import * as React from 'react';

import { useStyles2, useTheme2, ConfirmModal, Collapse } from '@grafana/ui';
import { getStyles } from './getSectionsStyles';
import { AppContext } from 'components/SimplePanel';
import CopyToClipboardButton from 'hooks/useCopyToClipboard';

interface ConfirmationProps {
  setShowConfirmModal: (value: React.SetStateAction<boolean>) => void;
  showConfirmModal: boolean;
  hexString: string;
  confirmSettings: () => void;
  modalTitle: string;
}

export const ConfirmationModal: React.FunctionComponent<ConfirmationProps> = ({
  showConfirmModal,
  setShowConfirmModal,
  hexString,
  confirmSettings,
  modalTitle,
}) => {
  const { dico } = React.useContext(AppContext);
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);

  const { details, submitSettings, sendConfirmationMessage } = dico.dico || {};

  const theme = useTheme2();
  const styles = useStyles2(() => getStyles(theme));

  React.useEffect(() => {
    if (!showConfirmModal) {
      setIsDetailsOpen(false);
    }
  }, [showConfirmModal]);

  return (
    <ConfirmModal
      isOpen={showConfirmModal}
      title={modalTitle}
      confirmText={submitSettings}
      onConfirm={() => confirmSettings()}
      onDismiss={() => setShowConfirmModal(false)}
      body={
        <React.Fragment>
          <div style={{ marginBottom: '32px' }}>
            <div style={{ marginBottom: '16px' }}>{sendConfirmationMessage}</div>
            <Collapse
              collapsible
              label={details}
              isOpen={isDetailsOpen}
              onToggle={() => setIsDetailsOpen(!isDetailsOpen)}>
              <div
                style={{
                  marginBottom: '4px',
                  fontSize: '14px',
                }}>
                Hex string to be sent :
              </div>
              <div className={styles.copyToClipBoardContainer}>
                <div style={{ width: '270px', overflowX: 'auto' }}>{hexString}</div>
                <CopyToClipboardButton content={hexString} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>
      }
    />
  );
};
