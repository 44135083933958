import * as React from 'react';
import { getAppEvents, getBackendSrv } from '@grafana/runtime';
import { AppEvents, getProcessedDataFrames } from '@grafana/data';
import {
  getCompressionOptions,
  getFftSpectrumTypes,
  getNumberOptions,
  getOrientations,
  getPeriodicityModes,
  getSensorTypes,
  getSpectrumTypesBasis,
} from 'utils/formOptions';
import { AppContext } from 'components/SimplePanel';
import { FFT_SETTINGS_LENGTH, SPECTRUM_ZOOM_DATA } from 'utils/constants';
import {
  convertToHex32b,
  convertToHex8b,
  getStoreSettingsEndpoint,
  replaceAt,
  triggerSettingsExpedition,
} from 'utils/helpers';
import { FftFormDTO } from 'types/form';
import { fft_model, MsgMapOpcodes } from 'utils/settings_models';
import { useSettingsFft } from './useSettingsFft';

export const useFormFFTZoomSettings = () => {
  const { client, gateway, beacon, datasource, panelData, dico } = React.useContext(AppContext);
  const { noClient, errorFftSettingsLength, errorSignatureSettings, errorSubmitFftSettings } = dico.dico || {};

  const appEvents = getAppEvents();
  const settings = useSettingsFft(panelData, dico.dico);

  const [isCurrentFFTEqualToCurrentSettings, setIsCurrentFFTEqualToCurrentSettings] = React.useState(true);
  const [settingsForm, setSettingsForm] = React.useState({} as FftFormDTO);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [hexString, setHexaString] = React.useState('');

  // endpoint
  const STORE_SETTINGS_ENDPOINT = getStoreSettingsEndpoint(datasource.dashGen.uid);

  // very crucial to specify responseType (otherwise this generates an error)
  const PERIODICITY_MODES_OPTIONS = getPeriodicityModes();
  const PERIODICITY_STEPS_OPTIONS = getNumberOptions(2, 11);
  const SENSOR_TYPES_OPTIONS = getSensorTypes(dico);
  const ORIENTATION_OPTIONS = getOrientations();
  const COMPRESSION_OPTIONS = getCompressionOptions();
  const SPECTRUM_TYPES_MICRO = getSpectrumTypesBasis(dico);
  const SPECTRUM_TYPES_ACCELERO = getFftSpectrumTypes(dico);

  const checkFftSettings = (form: FftFormDTO) => {
    if (!Object.keys(form).length) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [errorSubmitFftSettings],
      });
      return;
    }

    if (!client || !gateway || !beacon) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [noClient],
      });
      return;
    }

    const data = getProcessedDataFrames(panelData?.series);
    // Get the result of :
    // SELECT bin0000, handle FROM Signature_SpectrumZoom WHERE "device"='00-04-a3-0b-00-xx-xx-xx'  ORDER BY time DESC LIMIT 1
    const spectrumZoomData = data.filter((d) => d.name === SPECTRUM_ZOOM_DATA);

    // This checks if a bin0000 has been generated and therefore gives an handle.
    // This handle, if it exists, is then compared to the handle given by SettingsSignatureBackup.
    setShowConfirmModal(true);
    setSettingsForm(form);

    const handleFromSpectrumZoom = spectrumZoomData?.[0]?.fields[2]?.values?.get(0);
    // If handle from SpectrumZoom data is < to Signature settings handle, it means the settings have not been received
    if (handleFromSpectrumZoom < form['handle'] || (form['handle'] > 0 && !spectrumZoomData?.length)) {
      setIsCurrentFFTEqualToCurrentSettings(false);
      return;
    }
  };

  const getFFTZoomSettingsValue = () => {
    let valueBuiltWithOffset = '0'.repeat(FFT_SETTINGS_LENGTH);
    if (settingsForm.activation === 0) {
      // don't increment if activation is 0, only change activation value on settings value
      const convertedValue = convertToHex8b(settingsForm.activation);
      valueBuiltWithOffset = settings?.raw;
      valueBuiltWithOffset = replaceAt(
        valueBuiltWithOffset,
        convertedValue,
        fft_model['activation'].valueOffset,
        fft_model['activation'].sizeC
      );
    } else {
      settingsForm['handle'] += 1;
      // 8bits constraint
      if (settingsForm['handle'] % 256 === 0) {
        settingsForm['handle'] = 1;
      }

      for (const [setting, settingValue] of Object.entries(settingsForm)) {
        const valueOffset = fft_model[setting].valueOffset;
        const sizeC = fft_model[setting].sizeC;
        const convertedValue =
          typeof settingValue === 'string'
            ? sizeC === 2
              ? convertToHex8b(parseInt(settingValue, 10))
              : convertToHex32b(parseInt(settingValue, 10))
            : sizeC === 2
            ? convertToHex8b(settingValue)
            : convertToHex32b(settingValue);

        // mode "always-on" is 11, it has to be differentiated from 1/n on client's side
        if (setting === 'activation' && settingValue === 11) {
          valueBuiltWithOffset = replaceAt(valueBuiltWithOffset, convertToHex8b(1), valueOffset, sizeC);
        } else {
          valueBuiltWithOffset = replaceAt(valueBuiltWithOffset, convertedValue, valueOffset, sizeC);
        }
      }
    }
    return valueBuiltWithOffset;
  };
  // should add here hexstring instead of valueBuiltOffset
  const submitSettingsForm = () => {
    if (hexString.length !== FFT_SETTINGS_LENGTH) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [errorFftSettingsLength],
      });
      return;
    }

    sendSettings(hexString);
  };

  const sendSettings = async (signatureSettingsToSend: string) => {
    const COMMAND = MsgMapOpcodes.RX_SIG_SETTINGS_UPDATE;

    const settingsPayload = {
      client: client,
      mac_address: beacon,
      command: COMMAND,
      gateway: gateway,
      settings: signatureSettingsToSend?.toUpperCase(),
    };

    await getBackendSrv()
      .post(STORE_SETTINGS_ENDPOINT, settingsPayload, {
        responseType: 'text', // very crucial to specify responseType (otherwise this generates an error)
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        triggerSettingsExpedition(client, gateway, beacon, datasource.dashGen, dico?.dico);
        setShowConfirmModal(false);
      })
      .catch((err) => {
        console.log(err);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [errorSignatureSettings],
        });
      });
  };

  React.useEffect(() => {
    if (showConfirmModal && Object.keys(settingsForm)?.length) {
      const settings = getFFTZoomSettingsValue();
      if (settings) {
        setHexaString(settings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfirmModal, settingsForm]);

  return {
    checkFftSettings,
    COMPRESSION_OPTIONS,
    hexString,
    isCurrentFFTEqualToCurrentSettings,
    ORIENTATION_OPTIONS,
    PERIODICITY_MODES_OPTIONS,
    PERIODICITY_STEPS_OPTIONS,
    SENSOR_TYPES_OPTIONS,
    setShowConfirmModal,
    settingsForm,
    showConfirmModal,
    SPECTRUM_TYPES_ACCELERO,
    SPECTRUM_TYPES_MICRO,
    submitSettingsForm,
  };
};
