import * as React from 'react';
import { InlineField, InlineFieldRow, InlineLabel, InputControl, Select } from '@grafana/ui';

import { AppContext } from '../../SimplePanel';
import { useFormPublicSettings } from '../../../hooks/useFormPublicSettings';
import { SubSectionSchedulingsActivationProps } from '../../../types/form';
import { OrgRole } from '@grafana/data';

export const SubSectionPredictionPeriod: React.FunctionComponent<SubSectionSchedulingsActivationProps> = ({
  setValue,
  getValues,
  setError,
  clearErrors,
  errors,
  control,
  setIsMainModalOpen,
  handlePeriodChange,
  isSync,
}) => {
  const { dico, user } = React.useContext(AppContext);
  const { HOURS, MINUTES, SECONDS } = useFormPublicSettings(setIsMainModalOpen);

  // Translations
  const { fieldPredictionPeriod } = dico?.dico || {};

  return (
    <InlineFieldRow width={'100%'}>
      <InlineField
        label={fieldPredictionPeriod}
        labelWidth={24}
        htmlFor="predictionHours"
        invalid={!!errors.prediction_hours}
        error={errors.prediction_hours?.message}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              options={HOURS}
              inputId="predictionHours"
              onChange={(e) =>
                handlePeriodChange(
                  e.value,
                  setValue,
                  getValues,
                  setError,
                  clearErrors,
                  'prediction_hours',
                  'ambient_hours'
                )
              }
              disabled={isSync}
              invalid={invalid}
              width={12}
            />
          )}
          control={control}
          name="prediction_hours"
        />
      </InlineField>
      <InlineField>
        <InlineLabel>h</InlineLabel>
      </InlineField>
      <InlineField
        labelWidth={24}
        htmlFor="predictionMinutes"
        invalid={!!errors.prediction_minutes}
        error={errors.prediction_minutes?.message}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              options={MINUTES}
              inputId="predictionMinutes"
              onChange={(e) =>
                handlePeriodChange(
                  e.value,
                  setValue,
                  getValues,
                  setError,
                  clearErrors,
                  'prediction_minutes',
                  'ambient_minutes'
                )
              }
              disabled={isSync}
              invalid={invalid}
              width={12}
            />
          )}
          control={control}
          name="prediction_minutes"
        />
      </InlineField>
      <InlineField>
        <InlineLabel>min</InlineLabel>
      </InlineField>
      {user.orgRole === OrgRole.Admin && (
        <>
          <InlineField
            labelWidth={24}
            htmlFor="predictionSeconds"
            invalid={!!errors.ambient_seconds}
            error={errors.ambient_seconds?.message}>
            <InputControl
              render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                <Select
                  {...field}
                  options={SECONDS}
                  inputId="predictionSeconds"
                  onChange={(e) =>
                    handlePeriodChange(
                      e.value,
                      setValue,
                      getValues,
                      setError,
                      clearErrors,
                      'prediction_seconds',
                      'ambient_seconds'
                    )
                  }
                  width={12}
                  invalid={invalid}
                  disabled={isSync}
                />
              )}
              control={control}
              name="prediction_seconds"
            />
          </InlineField>
          <InlineField shrink>
            <InlineLabel>s</InlineLabel>
          </InlineField>
        </>
      )}
    </InlineFieldRow>
  );
};
